<template>
    <div>
        
        <b-row v-if="errors.length > 0" class="mt-0 mb-2">
            <b-col cols="12">
                <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key ="index" variant="danger" dismissible>{{item.message}}</b-alert>
            </b-col>
        </b-row>
        <b-row class="mt-0">
        <b-col cols="12" class="text-center"><p>Please enter the authorization code</p></b-col>
        </b-row>
        <b-row class="mt-0">
            <b-col cols="12">
                <b-form-input v-model.lazy="validationCode" placeholder="Code"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
        <b-col cols="12" class="text-center">
            <b-button @click="hideModal()" class="mr-2 ml-2" variant="danger">Cancel</b-button>
            <b-button variant="primary" @click="navigateToManageDevice()" class="mr-2 ml-2">Done</b-button>
        </b-col>
        </b-row>
        <div class="d-block"></div>
    </div>
</template>

<script>
export default {
    data:() => ({
        errors: []
    }),
    methods: {
        
        navigateToManageDevice(){
            this.errors = []
            let passcode = localStorage.getItem('username')
            let date = new Date()
            passcode += ' ' + date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear().toString()  

            if (passcode === this.validationCode) {
                this.$store.commit('changeScreen', 'deviceManager')
                this.$router.push({path: '/deviceManager'})
                this.$root.$emit('bv::hide::modal', 'modal-navigateToManageDevice', '#btnShow')
            }
            else {
                this.errors.push({message: 'Please contact Admin for an authorization code'})
            }
        },
        hideModal() {
            this.$root.$emit('bv::hide::modal', 'modal-navigateToManageDevice', '#btnShow')
        }
    }
}
</script>
