<template>
  <div>
    <b-navbar toggleable="md" type="dark" :sticky="true" :class="show === true ? 'navbar-show' : ''">

    <b-navbar-toggle target="nav-collapse" @click="show = !show"></b-navbar-toggle>
    <b-navbar-brand href="#">Electrosys - Remote Montoring</b-navbar-brand>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item  @click="goToPage('/home', 'home')" :active="currentScreen === 'home'">
        <!-- <b-nav-item  @click="goToPage('/home', 'home')" > -->

          <b-row class="mt-0 normal-menu" align-h="center" >
            <span>Home</span>
          </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <span class="ml-3">Home</span>
          </b-row>
        </b-nav-item>
        <b-nav-item @click="goToPage('/devices', 'devices')" :active="currentScreen === 'devices'">
        <!-- <b-nav-item @click="goToPage('/devices', 'devices')" > -->

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Select Device</span>
            </b-row>
            
            <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
                <span class="ml-3">Select Device</span>
            </b-row>
        </b-nav-item>
        <b-nav-item @click="openModalForDevice()" :active="currentScreen === 'deviceManager'">
        <!-- <b-nav-item @click="goToPage('/deviceManager', 'deviceManager')" > -->

            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Device Settings</span>
            </b-row>
            
            <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
                <span class="ml-3">Device Settings</span>
            </b-row>
        </b-nav-item>
        <b-nav-item  @click="goToPage('/help', 'help')" :active="currentScreen === 'help'">
        <!-- <b-nav-item  @click="goToPage('/help', 'help')"> -->
            <b-row class="mt-0 normal-menu" align-h="center">
              <span>Help</span>
            </b-row>
            
          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <span class="ml-3">Help</span>
          </b-row>
        </b-nav-item>
        <!-- <b-nav-item  @click="goToPage('/generator', 'generator')" :active="currentScreen === 'generator'"> -->
        <b-nav-item  @click="logout()" >

          <b-row class="mt-0 normal-menu" align-h="center">
            <span>Log out</span>
          </b-row>

          <b-row class="stacked-menu pl-4 pr-4" align-h="end" align-v="center">
              <span class="ml-3">Log out</span>
          </b-row>
        </b-nav-item>
      </b-navbar-nav>

    </b-collapse>
    
  </b-navbar>

  
    <!-- <b-overlay :show="show" variant="dark"> -->
      <router-view/>
    <!-- </b-overlay> -->
    
  <b-modal id="modal-1" hide-footer>
    <b-row>
      <b-col cols="12" class="text-center"><p>Are you sure you want to sign out?</p></b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="text-center">
        <b-button @click="hideModal" class="mr-2 ml-2">No</b-button>
        <b-button variant="primary" @click="logout" class="mr-2 ml-2">Yes</b-button>
      </b-col>
    </b-row>
    <div class="d-block"></div>
  </b-modal>
  
  <b-modal id="modal-navigateToManageDevice" hide-footer>
      <enterCodeContent/>
  </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import enterCodeContent from './components/enterCodeContent.vue'

export default {
  components: {
      enterCodeContent
  },
  data: () => ({
    show: false
  }),
  methods: {
    goToPage(routePath, location){
      if (location !== this.currentScreen) {
        this.$router.push({path: routePath})
        this.$store.commit('changeScreen', location)
      }
        this.show = false
    },
    showModal() {
      this.$root.$emit('bv::show::modal', 'modal-1', '#btnShow')
    },
    hideModal() {
      this.$root.$emit('bv::hide::modal', 'modal-1', '#btnShow')
    },
    logout() {
      this.$store.commit('clearUserInfo')
      this.$router.push({path: '/'})
    },
    openModalForDevice(){
      this.$root.$emit('bv::show::modal', 'modal-navigateToManageDevice', '#btnShow')
      this.show = false
    }
  },
  computed:{
      ...mapState(['currentScreen'])
  }
}
</script>
